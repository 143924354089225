import { render, staticRenderFns } from "./scholarshipCourse.vue?vue&type=template&id=3fe89e53&scoped=true&"
import script from "./scholarshipCourse.vue?vue&type=script&lang=js&"
export * from "./scholarshipCourse.vue?vue&type=script&lang=js&"
import style0 from "./scholarshipCourse.vue?vue&type=style&index=0&id=3fe89e53&scoped=true&lang=css&"
import style1 from "./scholarshipCourse.vue?vue&type=style&index=1&id=3fe89e53&lang=scss&scoped=true&"
import style2 from "./scholarshipCourse.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fe89e53",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VIcon,VRow,VSpacer})
